.footer-head {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 16px 0;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .store {
      display: flex;
      align-items: center;
      .sperator {
        height: 40px;
        border-left: 1px solid #eee;
        margin: 0 22px;
      }

      button {
        background: transparent;
        border: 0;
        padding: 0;
        display: flex;
        align-items: center;
        text-align: left;
        i {
          font-size: 22px;
          margin-right: 16px;
        }
        .on-it {
          font-size: 12px;
          margin-bottom: 0;
        }
        .store-name {
          margin-bottom: 0;
          font-weight: 700;
        }
      }
    }
  }
}

#footer {
  padding: 40px 0;
  h2 {
    font-weight: 700;
    font-size: 16px;
  }
  a {
    color: #4c4847;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.sub-footer {
  border-top: 1px solid #eee;
  padding: 20px 0;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .copy-links {
      display: flex;
      align-items: center;
      align-self: start;
      span {
        margin: 0 16px;
        transform: translateY(-3px);
      }
      a {
        color: #000;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .social-links {
      a {
        color: #000;
        text-decoration: none;
        margin-left: 16px;
      }
    }
  }
}
