.stays-section {
  padding-top: 40px;
}
.stay-grid-card {
  position: relative;
  z-index: 1;
  border-radius: 20px;
  overflow: hidden;
  .image {
    position: relative;
    width: 100%;
    padding-top: 130%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba(#000, 0.3);
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
  &.middle {
    .image {
      padding-top: calc(65% - 12px);
    }
  }
  .content {
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 20px;
    .title {
      color: #fff;
      font-size: 22px;
      margin-bottom: 0;
    }
    .stays {
      color: #fff;
      opacity: 0.7;
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}

.stay-grid-card-activity {
  position: relative;
  z-index: 1;
  border-radius: 20px;
  overflow: hidden;
  .image {
    position: relative;
    width: 100%;
    padding-top: 70%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba(#000, 0.3);
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
  &.middle {
    .image {
      padding-top: calc(65% - 12px);
    }
  }
  .content {
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 20px;
    .title {
      color: #fff;
      font-size: 22px;
      margin-bottom: 0;
    }
    .stays {
      color: #fff;
      opacity: 0.7;
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}

.explore-card {
  display: flex;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    object-fit: cover;
  }
  .title {
    font-size: 24px;
  }
  p {
    margin-bottom: 0;
  }
}

.become-a-host {
  .wrapper {
    padding: 100px;
    position: relative;
    .circle-wrapper {
      background: #fbe3d9;
      position: absolute;
      overflow: hidden;
      border-radius: 22px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      .ci {
        position: absolute;
        width: 100%;
        display: block;
        padding-top: 6%;
        background: #fff;
        left: 0;
        border-radius: 100%;

        &-top {
          bottom: calc(100% - 23px);
        }
        &-bottom {
          top: calc(100% - 23px);
        }
      }
    }
    .main-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
    }
    .content {
      position: relative;
      z-index: 2;
      h2 {
        font-size: 36px;
      }
      p {
        font-size: 22px;
      }
    }
    .profile-card-wrapper {
      position: relative;
      width: 200px;
      height: 100px;
      &.left {
        .profile-card {
          &.profile {
            &-1 {
              top: 100%;
              right: 0;
            }
            &-2 {
              bottom: 100%;
              right: 50px;
            }
            &-3 {
              right: calc(100% + 20px);
              top: 50%;
            }
            &-4 {
              left: 90%;
              bottom: 50%;
            }
          }
        }
      }
      &.right {
        .profile-card {
          &.profile {
            &-1 {
              top: 100%;
              left: 0;
            }
            &-2 {
              bottom: 100%;
              left: 50px;
            }
            &-3 {
              left: calc(100% + 20px);
              top: 50%;
            }
            &-4 {
              right: 90%;
              bottom: 50%;
            }
          }
        }
      }
      .profile-card {
        position: absolute;

        &.sm {
          .profile-card-content-wrapper {
            width: 70px;
          }
        }

        .profile-card-content-wrapper {
          width: 200px;
          position: relative;
          z-index: 3;

          .detail {
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 10px;
            background: #fff;
            padding: 10px;
            border-radius: 10px;

            h3 {
              margin-bottom: 0;
              font-size: 18px;
            }
            p {
              margin-bottom: 0;
              font-size: 12px;
            }
          }
          .image-wrapper {
            position: relative;

            width: 100%;
            padding-top: 120%;
            img {
              border-radius: 10px;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.gateways-section {
  margin-top: 100px;
  padding-bottom: 60px;
  .tabls-btns {
    margin-bottom: 16px;
    .btn {
      padding: 0;
      outline: none !important;
      box-shadow: none !important;
      margin-right: 16px;
      padding-bottom: 8px;
      border-radius: 0;
      &.active {
        border-bottom: 1px solid;
        font-weight: bold;
      }
    }
  }
}

.info-card {
  border: 1px solid #e7e6e6;
  border-radius: 12px;
  padding: 12px;
  .imageWrapper {
    position: relative;
    padding-top: 80%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 12px;
    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .rate {
      position: absolute;
      top: 15px;
      right: 15px;
      background: #fff;
      padding: 2px 6px;
      font-weight: 700;
      border-radius: 6px;
    }
  }
  .content {
    .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .dec {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
    }
    .detail {
      font-size: 12px;
      opacity: 0.7;
    }
    .price {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}
