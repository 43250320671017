.owl-theme {
  .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    [class*="owl-"] {
      color: #fff;
      font-size: 14px;
      margin: 5px;
      padding: 4px 7px;
      background: #d6d6d6;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;
    }
    [class*="owl-"]:hover {
      background: $blue;
      color: #fff;
      text-decoration: none;
    }
    .disabled {
      opacity: 0.5;
      cursor: default;
    }
    &.disabled + .owl-dots {
      margin-top: 10px;
    }
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;
      span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: #d6d6d6;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: 30px;
      }
      &.active,
      &:hover {
        span {
          background: #000;
        }
      }
    }
  }
}
