.home-banner {
  .owl-nav {
    .owl-prev,
    .owl-next {
      font-size: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      border-radius: 100%;
      background: #ffffff;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #bdbdbd;
      }
      &::before {
        color: #2e2b2b;
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-size: 16px;
      }
    }
    .owl-prev {
      left: 130px;
      &:before {
        content: "\f053";
      }
    }
    .owl-next {
      right: 130px;
      &::before {
        content: "\f054";
      }
    }
  }
  .banner-image-holder {
    padding: 0 80px;
    position: relative;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    text-align: center;
    color: #fff;
    width: 100%;
    max-width: 300px;
    h2 {
      font-size: 36px;
    }
    p {
      font-size: 22px;
      opacity: 0.7;
    }
    .btn {
      padding: 12px 20px;
    }
  }
  .banner-image-wrapper {
    height: 600px;
    width: 100%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(#000, 0.3);
    }
    .main-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }
}

.go-pro-banner {
  padding: 60px 0;
  .content-wrapper {
    background: {
      size: cover;
      position: center;
    }
    padding: 40px 100px;
    border-radius: 12px;
    .content {
      max-width: 370px;
      color: #fff;
      h2 {
        font-size: 16px;
        font-weight: 400;
      }
      p {
        color: rgba(#fff, 0.8);
        .bg-primary {
          padding: 2px;
          border-radius: 4px;
        }
      }
      .btn {
        background: #fff;
      }
    }
  }
}
