.banner-filter-holder {
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  z-index: 2;
  width: 100%;
  padding: 0 115px;
}
.search-filter {
  background: #fff;
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  .input-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    .location-col {
      flex: 0 0 40%;
      max-width: 40%;
      @media (max-width: $desktop) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 16px;
      }
    }
    .s-cols {
      flex: 0 0 20%;
      max-width: 20%;
      @media (max-width: $desktop) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      @media (max-width: $phone) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 16px;
      }
    }
    .s-input {
      .form-label {
        margin-bottom: 0;
        font-weight: 700;
      }
      .form-control {
        border: 0px;
        padding: 0px;
        box-shadow: none;
        &::placeholder {
          color: #818182;
        }
      }
    }
  }

  .btn-wapper {
  }
}
