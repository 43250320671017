#header {
  background: #fff;
  position: sticky;
  top: 0;
  left: 0;
  padding: 15px 0;
  z-index: 999;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-wrapper {
      display: flex;
      align-items: center;
      .seprator {
        border-left: 1px solid #eaeae8;
        margin: 0 22px;
        height: 30px;
      }
      .menu-items {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        li {
          list-style: none;
          margin-right: 20px;
          a {
            color: #565353;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.detail-header {
  background: #fbf9f9;
  padding: 12px 0;
  border-top: 1px solid #e9e8e8;
  border-bottom: 1px solid #e9e8e8;
  .type {
    font-size: 12px;
    margin-bottom: 0;
    color: #807e7c;
  }
  .name {
    margin-bottom: 0;
  }
}

.activity-filter {
  padding: 12px 0;
  .wrapper {
    display: flex;
    justify-content: space-between;
    .date {
      font-size: 12px;
      margin-bottom: 0;
    }
    .stays {
      font-weight: 700;
      margin-bottom: 0;
    }
    .filter-btn {
      .btn {
        margin-left: 12px;
        border-color: #efefee;
        background: transparent;
        font-weight: 500;
        box-shadow: none;

        &:hover,
        &.active {
          border-color: #030303;
        }
      }
    }
  }
}
